import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import { Alert, Box } from "@amzn/awsui-components-react";
import { QuickSightDashboard } from "./QuicksightDashboard";
import PageHeader from "../PageHeader";
import DarkModeContext from "../../contexes/DarkModeContext";
import { StageContext } from "../../contexes/OrderIdContext";

class DashboardErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box padding="l">
          <Alert type="error" header="Error" dismissible={false}>
            An unexpected error occurred. Please refresh the page.
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}

const Dashboard: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [darkMode, setDarkMode] = useState(false);
  const stageParam = searchParams.get("stage") || "Prod";

  return (
    <DashboardErrorBoundary>
      <DarkModeContext.Provider value={darkMode}>
        <StageContext.Provider value={stageParam}>
          <PageHeader setDarkMode={setDarkMode} />
          <AppLayout
            content={
              <QuickSightDashboard
                stage={stageParam}
                onLoad={() => console.log("Dashboard loaded")}
              />
            }
            contentType="default"
            headerSelector="#h"
            maxContentWidth={1700}
            navigationHide
            toolsHide
          />
        </StageContext.Provider>
      </DarkModeContext.Provider>
    </DashboardErrorBoundary>
  );
};

export default Dashboard;

import {
  DigitalBusinessDocument,
  DigitalCostDocument,
} from "../types/documents";
import { AccountingEventsGroup } from "../types/events";
import { CanonicalBusinessEvent } from "../types/cbes";

export const isAccountingEventsGroup = (
  data:
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | DigitalCostDocument
    | CanonicalBusinessEvent
): data is AccountingEventsGroup => "accountingEvents" in data;

export const isDigitalCostDocument = (
  data:
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | DigitalCostDocument
    | CanonicalBusinessEvent
): data is DigitalCostDocument =>
  "documentType" in data && data.documentType === "DigitalCostDocument";

export const isDigitalBusinessDocument = (
  data:
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | DigitalCostDocument
    | CanonicalBusinessEvent
): data is DigitalBusinessDocument =>
  "documentType" in data && data.documentType !== "DigitalCostDocument";

export const isCanonicalBusinessEvent = (
  data:
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | DigitalCostDocument
    | CanonicalBusinessEvent
): data is CanonicalBusinessEvent => "records" in data;

import { Link, TableProps } from "@amzn/awsui-components-react";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { Transaction } from "../lusca-search-api/generated-src";
import { GL_MAPPING } from "../hooks/useSummaryData";
import {
  BETA_STAGE,
  GAMMA_STAGE,
  PERMISSION_LINK,
  PROD_STAGE,
} from "../constants";

export const PAYMENT_METHOD_OPTIONS: OptionDefinition[] = [
  { label: "All", value: "all" },
  { label: "AMP", value: "AMP" },
  { label: "Apple", value: "Apple" },
  { label: "Google", value: "Google" },
];

export const TIME_RANGES: readonly OptionDefinition[] = [
  {
    label: "Custom date",
    value: "custom",
  },
  {
    label: "5 mins ago",
    value: "5min",
  },
  {
    label: "15 mins ago",
    value: "15min",
  },
  {
    label: "1 day ago",
    value: "24hours",
  },
  {
    label: "Last week",
    value: "1week",
  },
  {
    label: "Last month",
    value: "1month",
  },
] as const;

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Transaction>[] = [
  {
    id: "id",
    header: "Transaction ID",
    width: 350,
    cell: (item: Transaction) => (
      <Link
        href={`?stage=${encodeURIComponent(
          item.stage
        )}&transactionId=${encodeURIComponent(item.id)}`}
      >
        {item.id}
      </Link>
    ),
    sortingField: "id",
  },
  {
    id: "usecase",
    header: "Usecase",
    cell: (item: Transaction) => item.usecase,
    sortingField: "usecase",
    width: 250,
  },
  {
    id: "stage",
    header: "Stage",
    cell: (item: Transaction) => item.stage,
    sortingField: "stage",
  },
  {
    id: "country",
    header: "Country",
    cell: (item: Transaction) => item.country,
    sortingField: "country",
  },
  {
    id: "gl",
    header: "GL",
    cell: (item: Transaction) =>
      `${GL_MAPPING[item.gl] || item.gl} (${item.gl})`,
    sortingField: "gl",
  },
  {
    id: "paymentMethod",
    header: "Payment",
    cell: (item: Transaction) => item.paymentMethod,
    sortingField: "paymentMethod",
    width: 150,
  },
  {
    id: "processedTime",
    header: "Processed Time",
    width: 200,
    cell: (item: Transaction) => {
      const date = new Date(item.processedTime);
      return date.toLocaleString();
    },
    sortingField: "processedTime",
  },
];
export const TABLE_PREFERENCES = {
  pageSize: 15,
  contentDensity: "comfortable" as const,
  stickyHeader: true,
  stickyColumns: { first: 0, last: 0 },
  wrapLines: false,
};

export interface EmptyStateConfig {
  heading: string;
  message: string;
  link?: string;
}

export interface EmptyStateDefinition {
  default: EmptyStateConfig;
  filtered: EmptyStateConfig;
  permission: EmptyStateConfig;
}

export const RECENT_TRXN_EMPTY_STATE: EmptyStateDefinition = {
  default: {
    heading: "No transactions",
    message: "No recent transactions to display.",
  },
  filtered: {
    heading: "No matches",
    message: "We can't find any matches for your search.",
  },
  permission: {
    heading: "Permission Error",
    message:
      "You may not have permission to view transactions. Please request access through the permissions portal.",
    link: PERMISSION_LINK,
  },
};
export const STAGES = [PROD_STAGE, GAMMA_STAGE, BETA_STAGE].map((stage) => ({
  label: stage,
  value: stage,
}));

export const DEFAULT_OPTION: OptionDefinition = {
  label: "All",
  value: "All",
};

export const GLS = [
  DEFAULT_OPTION,
  ...Object.entries(GL_MAPPING).map(([id, name]) => ({
    label: `${name} (${id})`,
    value: id,
  })),
];

const CLASSIC_MARKETS = ["US", "DE", "JP", "GB"];
const ELCANO_MARKETS = ["FR", "ES", "IN", "CA", "NL", "IT"];
const OTHER_MARKETPLACES = ["AU", "PL", "SE", "BE", "TR", "MX", "BR"];
const ROW_NA_MARKETS = ["AR", "CL", "CO", "CR", "DO", "GT", "PY", "PE"];
const ROW_EU_MARKETS = ["DK", "FI", "NO", "PT", "ZA", "NG", "KE"];
const ROE_EU_MARKETS = ["CZ", "HU", "IS", "IE", "RO", "CH"];
const ROW_FE_MARKETS = ["ID", "MY", "NZ", "PH", "TW", "TH"];

export const GROUPED_MARKETPLACES = [
  DEFAULT_OPTION,
  {
    label: "Classic Markets (CMP)",
    options: CLASSIC_MARKETS.map((code) => ({
      label: code,
      value: code,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: "Elcano Markets",
    options: ELCANO_MARKETS.map((code) => ({
      label: code,
      value: code,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: "ROW NA",
    options: ROW_NA_MARKETS.map((code) => ({
      label: code,
      value: code,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: "ROW EU",
    options: ROW_EU_MARKETS.map((code) => ({
      label: code,
      value: code,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: "ROW FE",
    options: ROW_FE_MARKETS.map((code) => ({
      label: code,
      value: code,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: "ROE",
    options: ROE_EU_MARKETS.map((code) => ({
      label: code,
      value: code,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: "Other",
    options: OTHER_MARKETPLACES.map((code) => ({
      label: code,
      value: code,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
];
const SALE_USECASES = [
  "OptimisticFulfillment",
  "ChargeSuccess",
  "ChargePending",
];

const CANCEL_USECASES = [
  "ChargeFail",
  "OrderClosedNonPay",
  "CancelBeforeChargeSuccess",
  "Cancelled",
  "PurchaseChargeBack",
  "FailureAfterSuccess",
];

const REFUND_USECASES = ["Refunded", "RefundPending"];

const ROYALTY_USECASES = ["StandaloneCost", "StandaloneCostRefund"];

export const GROUPED_USECASES = [
  DEFAULT_OPTION,
  {
    label: "Sale",
    options: SALE_USECASES.map((usecase) => ({
      label: usecase,
      value: usecase,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: "Cancel",
    options: CANCEL_USECASES.map((usecase) => ({
      label: usecase,
      value: usecase,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: "Refund",
    options: REFUND_USECASES.map((usecase) => ({
      label: usecase,
      value: usecase,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: "Royalty",
    options: ROYALTY_USECASES.map((usecase) => ({
      label: usecase,
      value: usecase,
    })).sort((a, b) => a.label.localeCompare(b.label)),
  },
];

export const getModifiedOptions = (
  options: readonly (
    | OptionDefinition
    | { label: string; options: OptionDefinition[] }
  )[],
  selectedOptions: readonly OptionDefinition[]
): (OptionDefinition | { label: string; options: OptionDefinition[] })[] => {
  const hasAllSelected = selectedOptions.some(
    (option) => option.value === DEFAULT_OPTION.value
  );

  return options.map((option) => {
    if ("options" in option) {
      // Handle grouped options
      return {
        ...option,
        options: option.options.map((subOption) => ({
          ...subOption,
          disabled: hasAllSelected,
        })),
      };
    }
    // Handle non-grouped options
    return {
      ...option,
      disabled: hasAllSelected && option.value !== DEFAULT_OPTION.value,
    };
  });
};

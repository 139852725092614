import {SourceEventsToVersion} from "../types/common";
import {AccountingEvent} from "../types/events";

export const isTvodAccountingEvent = (event: AccountingEvent) => {
    return event.glProductGroup == 318;
}

export const getPaymentMethod = (event: AccountingEvent) => {
    if (event.isEmpOrder) return "EMP";
    const paymentId = Object.keys(event.paymentInstruments || {})[0];
    const payment = event.paymentInstruments?.[paymentId];
    switch (payment?.instrument) {
        case "BankAccountPaymentInstrument":
            return "Direct Debit";
        default:
            return payment.instrument ?? "AMP";
    }
};

export const getPaymentStatus = (sourceEvents: SourceEventsToVersion | undefined) => {
    if (Object.keys(sourceEvents || {}).includes("OrderSettleSucceeded")) return "success"
    if (Object.keys(sourceEvents || {}).includes("OrderCompleted-ClosedNonPayment")) return "error";
    return "in-progress";
};

export const getPaymentStatusText = (event: AccountingEvent, method: string) => {
    if (getPaymentStatus(event.sourceEventsToVersion) === "success")
        return `Paid (${method})`;
    if (getPaymentStatus(event.sourceEventsToVersion) === "error")
        return `Failed (${method})`;
    return `Pending (${method})`;
};

export const getFulfillmentStatus = (event: AccountingEvent) => {
    const events =  Object.keys(event.sourceEventsToVersion || {});
    switch (true) {
        case events.includes("Cancelled"):
            return "error";
        case events.includes("PeriodStart"):
        case (isTvodAccountingEvent(event) && events.includes("OrderCompleted-CompleteSuccess")):
            return "success";
        case (!isTvodAccountingEvent(event) && !events.includes("PeriodStart")):
            return "error"
        default:
            return "in-progress";
    }
};

export const getFulfillmentText = (event: AccountingEvent) => {
    const events =  Object.keys(event.sourceEventsToVersion || {});
    switch (true) {
        case events.includes("Cancelled"):
            return "Cancelled";
        case events.includes("PeriodStart"):
        case (isTvodAccountingEvent(event) && events.includes("OrderCompleted-CompleteSuccess")):
            return "Fulfilled";
        case (!isTvodAccountingEvent(event) && !events.includes("PeriodStart")):
            return "No Period Start"
        default:
            return "Pending";
    }

};

export const hasPromotions = (event: AccountingEvent) =>
    event.discountsOnRevenue && Object.keys(event.discountsOnRevenue).length > 0;
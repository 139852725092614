import { AccountingEventsGroup } from "../types/events";
import { DigitalBusinessDocument } from "../types/documents";
import { CanonicalBusinessEvent } from "../types/cbes";

export function removeKeyFromJson(
  object:
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | CanonicalBusinessEvent,
  toRemove: string
) {
  if (toRemove in JSON.parse(JSON.stringify(object))) {
    delete object[toRemove];
  }
  return object;
}

import React, { useState } from "react";
import { Button, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import { getQuery } from "../../helpers/logUtils";
import JsonCompare from "./JsonCompare";
import { AccountingEventsGroup } from "../../types/events";
import { DigitalBusinessDocument } from "../../types/documents";
import { CanonicalBusinessEvent } from "../../types/cbes";
import {
  isAccountingEventsGroup,
  isCanonicalBusinessEvent,
  isDigitalBusinessDocument,
} from "../../helpers/typeChecks";
import ViewLogs from "./ViewLogs";

interface LogButtonsProps {
  identifier: string;
  stage: string;
  logGroups: string[];
  dateRange: [Date, Date];
  isDlq?: boolean;
  data?: (
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | CanonicalBusinessEvent
  )[];
}

const EventButtons: React.FC<LogButtonsProps> = ({
  identifier,
  stage,
  logGroups,
  dateRange,
  isDlq = false,
  data,
}) => {
  const logQuery = getQuery(identifier, isDlq);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  function getId(): string {
    if (!data?.length) return "";

    const firstItem = data[0];
    switch (true) {
      case isAccountingEventsGroup(firstItem):
        return (firstItem as AccountingEventsGroup).pk ?? "";
      case isDigitalBusinessDocument(firstItem):
        return (firstItem as DigitalBusinessDocument).id ?? "";
      case isCanonicalBusinessEvent(firstItem):
        return (firstItem as CanonicalBusinessEvent).pk ?? "";
      default:
        return "";
    }
  }

  return (
    <SpaceBetween alignItems="center" direction="horizontal" size="xxs">
      <ViewLogs
        stage={stage}
        logGroups={logGroups}
        logQuery={logQuery}
        dateRange={dateRange}
      />
      {data && (
        <>
          <Button onClick={handleModalOpen}>Compare JSON</Button>
          <Modal
            visible={isModalVisible}
            onDismiss={handleModalClose}
            header={`JSON Comparison - ${getId()}`}
            size="max"
          >
            <JsonCompare isModalVisible={isModalVisible} objects={data} />
          </Modal>
        </>
      )}
    </SpaceBetween>
  );
};

export default EventButtons;

import {
  Box,
  ExpandableSection,
  SpaceBetween,
  TextContent,
} from "@amzn/awsui-components-react";
import ReactJson from "react-json-view";
import { AccountingEventsGroup } from "../../types";
import {AccountingEventsSummarySection} from "./AccountingEventsSummarySection";

interface AccountingEventsViewProps {
  accountingEvents: AccountingEventsGroup["accountingEvents"];
  jsonViewProps: any;
}

export function AccountingEventsView({
  accountingEvents,
  jsonViewProps,
}: AccountingEventsViewProps) {
  return (
    <SpaceBetween size="l">
      {accountingEvents &&
        Object.keys(accountingEvents).map((key) => (
            <SpaceBetween size="xl" key={key}>
              {key.includes("REVENUE") && (
                  <AccountingEventsSummarySection event={accountingEvents[key]} />
              )}
                    <ExpandableSection
                        header={<Box variant="h4" margin={{top: "xxs"}}>
                          {key}
                          <br/>
                          {accountingEvents[key].creationDate && (
                              <TextContent>
                                <small>
                                  Created on:{" "}
                                  {new Date(accountingEvents[key].creationDate)
                                      .toISOString()
                                      .replace("T", " ")}
                                </small>
                              </TextContent>
                          )}
                        </Box>}
                        variant="container"
                    >
                      <ReactJson src={accountingEvents[key]} {...jsonViewProps} />
                    </ExpandableSection>
                  </SpaceBetween>
        ))}
    </SpaceBetween>
  );
}

import {
  Box,
  Container,
  Header,
  Link,
  SpaceBetween,
  Tabs,
} from "@amzn/awsui-components-react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StageContext } from "../contexes/OrderIdContext";
import SearchHistory from "./SearchHistory";
import RecentTransactions from "./RecentTransactions";

export default function LandingPage() {
  const stage = useContext(StageContext);
  const navigate = useNavigate();
  const location = useLocation();

  const activeTabId =
    location.pathname === "/find" ? "transactions" : "searches";

  return (
    <SpaceBetween size="l">
      <Container>
        <SpaceBetween size="xs">
          <Header variant="h2">
            Lusca - Prime Video&#39;s Accounting Engine{" "}
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href="https://w.amazon.com/bin/view/AmazonVideo/TRACK/Systems/Lusca/"
            >
              (Wiki)
            </Link>
          </Header>
          <Box variant="p">
            Lusca is a modern accounting engine built by Prime Video FinSys. It
            converts Prime Video transactions into Canonical Business Events,
            and books them into Amazon&apos;s ledger.
          </Box>
        </SpaceBetween>
      </Container>
      <StageContext.Provider value={stage}>
        <Tabs
          activeTabId={activeTabId}
          onChange={({ detail }) => {
            navigate(detail.activeTabId === "transactions" ? "/find" : "/");
          }}
          tabs={[
            {
              id: "searches",
              label: "Recent Searches",
              content: <SearchHistory />,
            },
            {
              id: "transactions",
              label: "Find Transactions",
              content: <RecentTransactions />,
            },
          ]}
        />
      </StageContext.Provider>
    </SpaceBetween>
  );
}

import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";

interface GlProductContextProps {
  glProduct: string;
  setGlProduct: React.Dispatch<React.SetStateAction<string>>;
}

const GlProductContext = createContext<GlProductContextProps | undefined>(
  undefined
);

export function GlProductProvider({ children }: { children: ReactNode }) {
  const [glProduct, setGlProduct] = useState<string>("");

  const value = useMemo(() => ({ glProduct, setGlProduct }), [glProduct]);

  return (
    <GlProductContext.Provider value={value}>
      {children}
    </GlProductContext.Provider>
  );
}

export const useGlProduct = () => {
  const context = useContext(GlProductContext);
  if (!context) {
    throw new Error("useGlProduct must be used within GlProductProvider");
  }
  return context;
};

import {
  Alert,
  Box,
  Button,
  CopyToClipboard,
  Header,
  Modal,
  SpaceBetween,
  ToggleButton,
} from "@amzn/awsui-components-react";
import { useContext, useState } from "react";
import ReactJson from "react-json-view";
import CodeView from "@amzn/awsui-code-view/code-view";
import DarkModeContext from "../../contexes/DarkModeContext";
import { BASE_16_CUSTOM_DARK_THEME } from "../../constants";

interface JsonModalProps {
  title: string;
  content: any;
  visible: boolean;
  onDismiss: () => void;
  initialCollapsed?: number;
  isMalformedJson?: boolean;
}

export function JsonModal({
  title,
  content,
  visible,
  onDismiss,
  initialCollapsed = 3,
  isMalformedJson = false,
}: JsonModalProps) {
  const darkMode = useContext(DarkModeContext);
  const [isExpanded, setIsExpanded] = useState(true);

  const handleRecordsToggle = ({
    detail,
  }: {
    detail: { pressed: boolean };
  }) => {
    setIsExpanded(detail.pressed);
  };

  const renderContent = () => {
    if (isMalformedJson) {
      return (
        <SpaceBetween direction="vertical" size="s">
          <Alert type="error" header="Malformed JSON Detected">
            The message contains invalid JSON and cannot be parsed. The raw
            content is displayed below.
          </Alert>
          <Box padding="s">
            <CodeView content={content as string} wrapLines />
          </Box>
        </SpaceBetween>
      );
    }

    return (
      <ReactJson
        src={content}
        displayDataTypes={false}
        sortKeys
        collapsed={isExpanded ? false : initialCollapsed}
        theme={darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default"}
        style={{ maxHeight: "70vh", overflow: "auto" }}
      />
    );
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss}
      size="large"
      header={<Header variant="h2">{title}</Header>}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <CopyToClipboard
              copyButtonText={isMalformedJson ? "Copy Message" : "Copy JSON"}
              copySuccessText="Copied to clipboard"
              copyErrorText="Error copying"
              textToCopy={
                typeof content === "string"
                  ? content
                  : JSON.stringify(content, null, 2)
              }
            />
            <ToggleButton
              onChange={handleRecordsToggle}
              pressed={isExpanded}
              iconName="zoom-in"
              pressedIconName="zoom-out"
            >
              {isExpanded ? "Collapse All" : "Expand All"}
            </ToggleButton>
            <Button variant="primary" onClick={onDismiss}>
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {renderContent()}
    </Modal>
  );
}

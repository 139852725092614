import {CbesApi, DashboardApi, DlqApi, TransactionsApi} from "./generated-src";
import {getAppSetting} from "../config/AppSettings";
import {Configuration} from "./generated-src/configuration";

export function DetailsApiFactory() {
  const apiBasePath = `https://${getAppSetting("apiUrl")}/jwt`;
  return new CbesApi(new Configuration({basePath: apiBasePath}));
}

export function DlqInfoApiFactory(): DlqApi {
  const apiBasePath = `https://${getAppSetting("apiUrl")}/jwt`;
  return new DlqApi(new Configuration({basePath: apiBasePath}));
}

export function DashboardApiFactory(): DashboardApi {
  const apiBasePath = `https://${getAppSetting("apiUrl")}/jwt`;
  return new DashboardApi(new Configuration({basePath: apiBasePath}));
}

export function RecentTransactionsApiFactory(): TransactionsApi {
  const apiBasePath = `https://${getAppSetting("apiUrl")}/jwt`;
  return new TransactionsApi(new Configuration({basePath: apiBasePath}));
}


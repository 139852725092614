import React from "react";
import {
  Button,
  ButtonGroup,
  CopyToClipboard,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import CodeView from "@amzn/awsui-code-view/code-view";
import { generateMerlonLogsUrl } from "../../helpers/logUtils";

interface ViewLogsProps {
  stage: string;
  logGroups: string[];
  logQuery: string;
  dateRange?: [Date, Date];
  timestamp?: Date;
  windowMinutes?: number;
  minimal?: boolean;
}

const ViewLogs: React.FC<ViewLogsProps> = ({
  stage,
  logGroups,
  logQuery,
  dateRange,
  timestamp,
  windowMinutes = 5,
  minimal = false,
}) => {
  const getDateRangeArgs = () => {
    if (dateRange) {
      return dateRange;
    }
    if (timestamp) {
      const startDate = new Date(timestamp);
      const endDate = new Date(timestamp);
      startDate.setMinutes(startDate.getMinutes() - windowMinutes);
      endDate.setMinutes(endDate.getMinutes() + windowMinutes);
      return [startDate, endDate] as [Date, Date];
    }
    return [];
  };

  const hasQuery = Boolean(logQuery);

  const generateUrl = () => {
    const dateRangeArgs = getDateRangeArgs();
    return generateMerlonLogsUrl(
      stage,
      logGroups,
      logQuery,
      ...(dateRangeArgs.length ? dateRangeArgs : [])
    );
  };

  if (minimal) {
    return (
      <ButtonGroup
        items={[
          {
            type: "icon-button",
            id: "copy-query",
            iconName: "copy",
            text: "Copy query",
            disabled: !hasQuery,
            popoverFeedback: (
              <StatusIndicator type="success">Query copied</StatusIndicator>
            ),
          },
          {
            type: "icon-button",
            id: "view-logs",
            iconName: "external",
            text: "Open in Cloudwatch",
            disabled: !hasQuery,
          },
        ]}
        onItemClick={({ detail }) => {
          if (detail.id === "copy-query") {
            navigator.clipboard.writeText(logQuery);
          } else if (detail.id === "view-logs") {
            window.open(generateUrl(), "_blank", "noopener noreferrer");
          }
        }}
        variant="icon"
        ariaLabel="Log actions"
      />
    );
  }

  return (
    <SpaceBetween alignItems="center" direction="horizontal" size="xxs">
      <Popover
        dismissButton={false}
        fixedWidth={false}
        position="bottom"
        size="large"
        triggerType="custom"
        content={
          <CodeView
            content={logQuery}
            actions={
              <CopyToClipboard
                copyButtonAriaLabel="Copy query"
                copyErrorText="Query failed to copy"
                copySuccessText="Query copied"
                textToCopy={logQuery}
              />
            }
          />
        }
      >
        <Button
          iconName="status-info"
          variant="inline-icon"
          disabled={!hasQuery}
          ariaLabel="View query"
        />
      </Popover>
      <Button
        href={hasQuery ? generateUrl() : undefined}
        target="_blank"
        rel="noopener noreferrer"
        iconName="external"
        disabled={!hasQuery}
      >
        View Logs
      </Button>
    </SpaceBetween>
  );
};

export default ViewLogs;

import React, { Suspense } from "react";
import {
  Button,
  Container,
  CopyToClipboard,
  Grid,
  Select,
  SelectProps,
} from "@amzn/awsui-components-react";
import { DigitalBusinessDocument } from "../../types/documents";
import { AccountingEventsGroup } from "../../types/events";
import { CanonicalBusinessEvent } from "../../types/cbes";
import { Differ, DiffResult, Viewer } from "json-diff-kit";
import "json-diff-kit/dist/viewer.css";
import "json-diff-kit/viewer-monokai.css";
import { removeKeyFromJson } from "../../helpers/jsonUtils";
import { isCanonicalBusinessEvent } from "../../helpers/typeChecks";
import { groupCBEByUsecase } from "../../helpers/cbeUtils";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";

export interface JsonCompareComponentProps {
  isModalVisible: boolean;
  objects: (
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | CanonicalBusinessEvent
  )[];
}

const createUsecaseOptions = (
  cbes: CanonicalBusinessEvent[]
): SelectProps.Option[] => {
  const groupedCBEs = groupCBEByUsecase(cbes);
  return Array.from(groupedCBEs.keys()).map((usecase) => ({
    value: usecase,
    label: usecase,
  }));
};

const createVersionOptions = (
  objects: any[],
  usecase?: string,
  destination?: string
): SelectProps.Option[] => {
  if (!objects?.length) return [];

  if (isCanonicalBusinessEvent(objects[0]) && usecase) {
    const filteredObjects = objects.filter(
      (cbe): cbe is CanonicalBusinessEvent =>
        cbe !== undefined &&
        cbe.usecaseName === usecase &&
        (usecase === "NoOp" || cbe.destination === destination)
    );

    return filteredObjects.map((_, index) => ({
      label: `Version ${index + 1}`,
      value: index.toString(),
    }));
  }
  const adder = "accountingEvents" in objects[0] ? 1 : 0; // DBD starts at v0
  return objects.map((_, index) => ({
    label: `Version ${index + adder}`,
    value: index.toString(),
  }));
};

const JsonCompareComponent: React.FC<JsonCompareComponentProps> = ({
  isModalVisible,
  objects,
}) => {
  let usecaseOptions: OptionDefinition[] = createUsecaseOptions(
    objects as CanonicalBusinessEvent[]
  );
  const [versionOptions, setVersionOptions] = React.useState<
    OptionDefinition[]
  >(createVersionOptions(objects));
  const destinationOptions: SelectProps.Option[] = [
    { label: "rACE", value: "RACE" },
    { label: "Flare", value: "FLARE" },
  ];

  const differ = new Differ({ arrayDiffMethod: "lcs" });
  const [diff, setDiff] = React.useState<[DiffResult[], DiffResult[]]>();
  const [diffUnchangedLinesOptions, setDiffUnchangedLinesOptions] = React.useState<boolean | object>(false)

  const [rightSelectedVersion, setRightSelectedVersion] =
    React.useState<SelectProps.Option>(
      versionOptions[versionOptions.length - 1]
    );
  const [leftSelectedVersion, setLeftSelectedVersion] =
    React.useState<SelectProps.Option>(
      versionOptions.length > 1
        ? versionOptions[versionOptions.length - 2]
        : rightSelectedVersion
    );
  const [selectedUsecase, setSelectedUsecase] =
    React.useState<SelectProps.Option>(usecaseOptions[0]);
  const [selectedDestination, setSelectedDestination] =
    React.useState<SelectProps.Option>(destinationOptions[0]);

  function handleDiffCheck() {
    const leftIndex = Number(leftSelectedVersion.value);
    const rightIndex = Number(rightSelectedVersion.value);
    const leftObject = removeKeyFromJson(objects[leftIndex], "raw_payload");
    const rightObject = removeKeyFromJson(objects[rightIndex], "raw_payload");

    const diff = differ.diff(leftObject, rightObject);
    // @ts-ignore
    setDiff(diff);
  }

  const handleSelectChange = React.useCallback(
    (setter: (option: SelectProps.Option) => void) =>
      ({ detail }: { detail: { selectedOption: SelectProps.Option } }) => {
        setter(detail.selectedOption);
      },
    []
  );

  function handleShowHideLines() {
    if (diffUnchangedLinesOptions === false) {
      setDiffUnchangedLinesOptions({
        threshold: 5,
        margin: 3,
        expandMoreLinesLimit: 5,
      })
    } else {
      setDiffUnchangedLinesOptions(false)
    }
  }

  React.useEffect(() => {
    if (isCanonicalBusinessEvent(objects[0])) {
      const newOptions = createVersionOptions(
        objects,
        selectedUsecase.value,
        selectedDestination.value
      );
      setVersionOptions(newOptions);
    }
  }, [objects, selectedUsecase, selectedDestination]);

  React.useEffect(() => {
    if (versionOptions.length > 0) {
      setRightSelectedVersion(versionOptions[versionOptions.length - 1]);
      setLeftSelectedVersion(
        versionOptions.length > 1
          ? versionOptions[versionOptions.length - 2]
          : versionOptions[versionOptions.length - 1]
      );
    }
  }, [versionOptions]);

  React.useEffect(() => {
    if (leftSelectedVersion && rightSelectedVersion) {
      handleDiffCheck();
    }
  }, [leftSelectedVersion, rightSelectedVersion]);

  if (!isModalVisible) {
    return null;
  }

  return (
    <Suspense fallback={<div>Loading content...</div>}>
      <Container>
        <Grid
          gridDefinition={[
            { colspan: 3 },
            { colspan: 3 },
            { colspan: 3 },
            { colspan: 3 },
            { colspan: 3 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 3 },
            { colspan: 2 },
            { colspan: 12 },
          ]}
        >
          <div />
          {isCanonicalBusinessEvent(objects[0]) && (
            <>
              <Select
                selectedOption={selectedUsecase}
                onChange={handleSelectChange(setSelectedUsecase)}
                options={usecaseOptions}
              />
              <Select
                selectedOption={selectedDestination}
                onChange={handleSelectChange(setSelectedDestination)}
                options={destinationOptions}
              />
            </>
          )}
          {!isCanonicalBusinessEvent(objects[0]) && (
            <>
              <div></div>
              <div></div>
            </>
          )}
          <div />
          <Select
            selectedOption={leftSelectedVersion}
            onChange={handleSelectChange(setLeftSelectedVersion)}
            options={versionOptions}
          />
          <CopyToClipboard
            copyButtonText={"Copy JSON"}
            copySuccessText="Copied to clipboard"
            copyErrorText="Error copying"
            textToCopy={JSON.stringify(
              objects[Number(leftSelectedVersion.value)],
              null,
              2
            )}
          />
          <Button onClick={handleShowHideLines}>{diffUnchangedLinesOptions === false ? "Hide unchanged lines" : "Show unchanged lines"}</Button>
          <Select
            selectedOption={rightSelectedVersion}
            onChange={handleSelectChange(setRightSelectedVersion)}
            options={versionOptions}
          />
          <CopyToClipboard
            copyButtonText={"Copy JSON"}
            copySuccessText="Copied to clipboard"
            copyErrorText="Error copying"
            textToCopy={JSON.stringify(
              objects[Number(rightSelectedVersion.value)],
              null,
              2
            )}
          />
          {diff && (
            <Viewer
              diff={diff}
              lineNumbers={true}
              highlightInlineDiff={true}
              hideUnchangedLines={diffUnchangedLinesOptions}
              inlineDiffOptions={{
                mode: "word",
                wordSeparator: " ",
              }}
              syntaxHighlight={{
                theme: "monokai",
              }}
              virtual={false}
            />
          )}
        </Grid>
      </Container>
    </Suspense>
  );
};

export default JsonCompareComponent;

import { dumpPrettyText, load } from "ion-js";
import {CanonicalBusinessEvent, ErrorDetails, ErrorRecord, FullErrorRecord} from "../types";

function formatErrorMessage(errors: ErrorRecord): string {
  const groupedErrors: { [key: string]: Set<string> } = {};

  for (const error of Object.values(errors) as unknown as FullErrorRecord[]) {
    switch (error.error_type) {
      case "credit_debit_unbalance_error": {
        (groupedErrors[error.error_type] ??= new Set()).add(
          error.error_message
        );
        break;
      }
      case "attribute_derivation_error": {
        const [header, details] = error.error_message.split(". ");
        const formattedDetails = details
          .replace(/\[(.*?)\]/g, "\n\t\t$1\n\t ")
          .replace("::", "\n\t\t")
          .replaceAll("{", "")
          .replaceAll("},", ", ");
        (groupedErrors[header] ??= new Set()).add(formattedDetails);
        break;
      }
      default: {
        groupedErrors[error.error_message] ??= new Set();
        break;
      }
    }
  }
  const formattedErrors: string[] = [];
  for (const [errorType, details] of Object.entries(groupedErrors)) {
    if (details.size === 0) {
      formattedErrors.push(errorType);
    } else {
      formattedErrors.push(`${errorType}:`);
      details.forEach((detail) => {
        formattedErrors.push(`\t- ${detail}`);
      });
    }
  }

  return formattedErrors.join("\n");
}

export function parseErrorDetails(
  stackTrace: string,
  destination: string,
  cbe: CanonicalBusinessEvent
): ErrorDetails {
  try {
    if (destination === "FLARE") {
      const ionMatch = stackTrace.match(/{[\s\S]*}/);
      if (ionMatch) {
        const ionData = load(ionMatch[0]);
        const userFriendlyMessage = ionData?.get("message")?.stringValue();
        const rawErrorDetails = dumpPrettyText(ionData?.get("errors"));
        const inputRequestUrl =
          ionData?.get("input_request_url")?.stringValue() || undefined;
        const errorType =
          ionData?.get("type")?.stringValue() || "Unknown Error";

        const parsedErrorDetails: ErrorRecord | undefined = ionData?.get(
          "errors"
        )
          ? (JSON.parse(JSON.stringify(ionData.get("errors"))) as ErrorRecord)
          : undefined;

        const displayMessage = parsedErrorDetails
          ? formatErrorMessage(parsedErrorDetails)
          : stackTrace;

        const errorRecords: ErrorRecord[] | undefined = ionData?.get(
          "errored_records"
        )
          ? (JSON.parse(
              JSON.stringify(ionData.get("errored_records"))
            ) as ErrorRecord[])
          : undefined;

        return {
          errorType,
          displayMessage,
          rawErrorDetails,
          inputRequestUrl,
          parsedErrorDetails,
          errorRecords,
          stackTrace,
        };
      }
    }

    // Fallback for rACE errors
    const errorTypeMatch = stackTrace.match(/^([\w.]+Exception):/);
    const errorType = errorTypeMatch ? errorTypeMatch[1] : "Unknown Error";
    const errorRecordMatch = stackTrace.match(/.*ignored_record_ids:\[((?:"\d+"(?:,\s*)?)+)\]/);
    const errorRecordIds = errorRecordMatch ? errorRecordMatch[1].split(",").map(id => id.replace(/"/g, '').trim()) : [];
    const errorRecords: ErrorRecord[] | undefined = errorRecordIds.length > 0
        ? errorRecordIds
            .map(id => Number(id))
            .map(id => JSON.parse(JSON.stringify(cbe.records[id])) as ErrorRecord)
        : undefined;

    return {
      errorType,
      displayMessage: stackTrace,
      stackTrace,
      errorRecords: errorRecords
    };
  } catch (error) {
    console.error("Error parsing error details:", error);
    return {
      errorType: "Parsing Error",
      displayMessage: stackTrace,
      stackTrace,
    };
  }
}

export const BADGE_COLORS = [
  "blue",
  "grey",
  "green",
  "red",
  "severity-critical",
  "severity-high",
  "severity-medium",
  "severity-low",
  "severity-neutral",
] as const;

type BadgeColor = (typeof BADGE_COLORS)[number];

export function getBadgeColour(errorType: string): BadgeColor {
  const index =
    errorType.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) %
    BADGE_COLORS.length;
  return BADGE_COLORS[index];
}

import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { RecentTransactionsResponse } from "../lusca-search-api/generated-src";
import { RecentTransactionsApiFactory } from "../lusca-search-api/LuscaSearchApiFactory";
import {
  RecentTransactionsSearchParams,
  TransactionsInfo,
} from "../types/common";

export default function useFetchRecentTransactions(
  params: RecentTransactionsSearchParams
): TransactionsInfo & { refetch: () => void } {
  const transactionsApi = RecentTransactionsApiFactory();

  const queryKey = JSON.stringify(params);

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    RecentTransactionsResponse,
    Error
  >(
    ["recentTransactions", queryKey],
    async () => {
      const { data }: AxiosResponse<RecentTransactionsResponse> =
        await transactionsApi.getRecentTransactions(
          params.stage,
          params.countries,
          params.gl,
          params.usecases,
          params.paymentMethod,
          params.endTime
        );
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      enabled: !!params.stage,
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  return {
    transactions: data?.transactions ?? [],
    executedQuery: data?.executedQuery,
    nextPageTimestamp: data?.nextPageTimestamp,
    isLoading: isLoading || isFetching,
    error: error || null,
    refetch,
  };
}

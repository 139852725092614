import { SpaceBetween } from "@amzn/awsui-components-react";
import { PaymentSection } from "./PaymentSection";
import { ProductSection } from "./ProductSection";
import { MetadataSection } from "./MetadataSection";
import { SummarySection } from "./SummarySection";
import { DigitalBusinessDocument } from "../../../types/documents";
import { useExpandableSections } from "../../../hooks/useExpandableSections";
import { ActionButtons } from "../../common/ActionButtons";

interface DBDSummaryProps {
  dbd: DigitalBusinessDocument;
  modalHandler: () => void;
}

export const DBD_INITIAL_SECTIONS = {
  payment: true,
  product: true,
  metadata: true,
} as const;

export default function DigitalBusinessDocumentView({
  dbd,
  modalHandler,
}: DBDSummaryProps) {
  const { expandedSections, toggleAllSections, handleSectionChange } =
    useExpandableSections(DBD_INITIAL_SECTIONS);

  const { payments, products } = dbd.body.body;

  const paymentId = Object.keys(payments ?? {})[0];
  const payment = paymentId ? payments[paymentId] : undefined;
  const productId = Object.keys(products ?? {})[0];
  const product = productId ? products[productId] : undefined;

  return (
    <SpaceBetween size="l">
      <ActionButtons
        modalHandler={modalHandler}
        toggleAllSections={toggleAllSections}
        copyConfig={
          dbd.raw_payload
            ? {
                textToCopy: JSON.stringify(dbd.raw_payload, null, 2),
                buttonText: "Copy for Redrive",
                successText: "Raw Dynamo JSON for redrives copied",
                errorText: "Error copying JSON to clipboard",
              }
            : undefined
        }
      />
      <SummarySection dbd={dbd} />
      <PaymentSection
        payment={payment}
        paymentId={paymentId}
        expanded={expandedSections.payment}
        onChange={handleSectionChange("payment")}
      />
      <ProductSection
        product={product}
        productId={productId}
        expanded={expandedSections.product}
        onChange={handleSectionChange("product")}
        marketplaceId={dbd.body.header.marketplaceId}
      />
      <MetadataSection
        metadata={dbd.body.metadata}
        expanded={expandedSections.metadata}
        onChange={handleSectionChange("metadata")}
      />
    </SpaceBetween>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import { Alert, Box, Spinner } from "@amzn/awsui-components-react";
import { useQuery } from "@tanstack/react-query";
import { DashboardApiFactory } from "../../lusca-search-api/LuscaSearchApiFactory";
import DarkModeContext from "../../contexes/DarkModeContext";

interface QuickSightDashboardProps {
  stage: string;
  onLoad?: () => void;
}

export const QuickSightDashboard: React.FC<QuickSightDashboardProps> = ({
  stage,
  onLoad,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dashboardExperienceRef =
    useRef<QuickSightEmbedding.DashboardExperience | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const darkModeContext = useContext(DarkModeContext);
  const [isDarkTheme, setIsDarkTheme] = useState(darkModeContext);

  const dashboardApi = DashboardApiFactory();

  const THEMES = {
    DARK: "arn:aws:quicksight:us-east-1:800989574994:theme/e42b2ecd-7436-45a0-a032-52469c99af64",
    LIGHT: "arn:aws:quicksight::aws:theme/CLASSIC",
  };

  const applyTheme = (newTheme: boolean) => {
    if (dashboardExperienceRef.current) {
      try {
        dashboardExperienceRef.current.setTheme(
          newTheme ? THEMES.DARK : THEMES.LIGHT
        );
        setIsDarkTheme(newTheme);
      } catch (err) {
        console.error("Error applying theme:", err);
      }
    } else {
      console.log("Dashboard not ready, setting initial theme state");
      setIsDarkTheme(newTheme);
    }
  };

  useEffect(() => {
    if (darkModeContext !== isDarkTheme) {
      applyTheme(darkModeContext);
    }
  }, [darkModeContext, isDarkTheme]);

  const { data: embedUrlData } = useQuery(
    ["dashboardEmbed", stage],
    async () => {
      const { data } = await dashboardApi.getDashboardEmbedUrl(stage);
      return data;
    },
    {
      enabled: !!stage,
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    }
  );

  useEffect(() => {
    if (
      embedUrlData?.embedUrl &&
      containerRef.current &&
      !dashboardExperienceRef.current
    ) {
      const embedDashboard = async () => {
        try {
          console.log("Embedding dashboard");
          const embeddingContext =
            await QuickSightEmbedding.createEmbeddingContext();
          const experience = await embeddingContext.embedDashboard(
            {
              url: embedUrlData.embedUrl,
              container: containerRef.current!,
              width: "100%",
              height: "100%",
              resizeHeightOnSizeChangedEvent: true,
              onChange: (event: QuickSightEmbedding.EmbeddingEvents) => {
                if (event.eventName === "FRAME_LOADED") {
                  setIsLoading(false);
                  onLoad?.();
                }
              },
            },
            {
              locale: "en-US",
              sheetOptions: {
                initialSheetId: undefined,
                singleSheet: false,
                emitSizeChangedEventOnSheetChange: true,
              },
              toolbarOptions: {
                export: true,
                undoRedo: true,
                reset: true,
                bookmarks: true,
              },
              themeOptions: {
                themeArn: isDarkTheme ? THEMES.DARK : THEMES.LIGHT,
              },
              attributionOptions: {
                overlayContent: false,
              },
            }
          );
          dashboardExperienceRef.current = experience;
          console.log("Dashboard embedded successfully");
          applyTheme(isDarkTheme);
        } catch (err) {
          console.error("Error embedding dashboard:", err);
          setError(
            err instanceof Error ? err : new Error("Failed to embed dashboard")
          );
          setIsLoading(false);
        }
      };

      embedDashboard();
    }
  }, [embedUrlData, stage, onLoad, isDarkTheme]);

  const formatQuickSightError = (err: unknown): string => {
    if (err instanceof Error) {
      try {
        const errorData = JSON.parse(
          err.message.replace("QuickSight Error: ", "")
        );
        return errorData.message?.[0] || err.message;
      } catch {
        return err.message.replace("QuickSight Error: ", "");
      }
    }
    return String(err);
  };

  if (error) {
    return (
      <Box padding="l">
        <Alert
          type="error"
          header="Error loading dashboard"
          dismissible={false}
        >
          {formatQuickSightError(error)}
        </Alert>
      </Box>
    );
  }

  return (
    <Box padding="n">
      {isLoading && (
        <Box margin="xxl" textAlign="center">
          <Spinner size="large" />
        </Box>
      )}
      <div
        ref={containerRef}
        style={{
          width: "100%",
          height: "calc(100vh - 60px)",
          visibility: isLoading ? "hidden" : "visible",
          opacity: isLoading ? 0 : 1,
          transition: "opacity 0.3s ease-in-out",
        }}
      />
    </Box>
  );
};

export default QuickSightDashboard;

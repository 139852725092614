import {
  AccountingEventsGroup,
  CanonicalBusinessEvent,
  DigitalBusinessDocument,
} from "../types";
import { MARKETPLACE_TO_REALM } from "./marketplaceUtils";

const stageToAccountId: { [key: string]: string } = {
  beta: "325864189811",
  gamma: "239039480348",
  prod: "800989574994",
};

const TIME_RANGE = {
  CBE: 30,
  DEFAULT: 5,
};

const minutesToMs = (minutes: number) => minutes * 60 * 1000;

const getAwsAccountId = (stage: string): string => {
  return stageToAccountId[stage.toLowerCase()] || stageToAccountId.prod; // Default to prod if not found
};

export const generateMerlonLogsUrl = (
  stage: string,
  logGroups: string[],
  query: string,
  startTime?: Date,
  endTime?: Date
) => {
  const region = "us-east-1";
  const accountId = getAwsAccountId(stage);
  const opsRoleArn = `arn:aws:iam::${accountId}:role/${stage.toLowerCase()}-ops-role`;
  const accountName = `pv-accounting-${stage.toLowerCase()}`;
  const encodeSpecial = (str: string) => {
    return str
      .replace(/\*/g, "*2A")
      .replace(/\n/g, "*0a")
      .replace(/\//g, "*2F")
      .replace(/=/g, "*3D")
      .replace(/\[/g, "*5B")
      .replace(/\]/g, "*5D")
      .replace(/\(/g, "*28")
      .replace(/\)/g, "*29")
      .replace(/"/g, "*22")
      .replace(/'/g, "*27")
      .replace(/,/g, "*2c")
      .replace(/@/g, "*40")
      .replace(/\|/g, "*7C")
      .replace(/:/g, "*3A")
      .replace(/ /g, "*20");
  };

  const encodedLogGroups = logGroups
    .map((group) => `~'${group.replace(/\//g, "*2F")}`)
    .join("");

  const encodedQuery = encodeSpecial(query);

  let timeComponent;
  if (startTime && endTime) {
    // Use absolute time if both start and end are provided
    try {
      const encodedStartTime = encodeURIComponent(
        startTime.toISOString()
      ).replace(/%/g, "*");
      const encodedEndTime = encodeURIComponent(endTime.toISOString()).replace(
        /%/g,
        "*"
      );
      timeComponent = `end~'${encodedEndTime}~start~'${encodedStartTime}~timeType~'ABSOLUTE`;
    } catch (error) {
      // Fallback to relative time if there's an error
      const endSeconds = 0;
      const startSeconds = -300; // 5 minutes
      timeComponent = `end~${endSeconds}~start~${startSeconds}~timeType~'RELATIVE`;
    }
  } else {
    // Use relative time if either start or end is missing
    const endSeconds = 0;
    const startSeconds = -300; // 5 minutes
    timeComponent = `end~${endSeconds}~start~${startSeconds}~timeType~'RELATIVE`;
  }

  const cloudWatchUrl = `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:logs-insights$3FqueryDetail$3D~(${timeComponent}~tz~'UTC~unit~'seconds~editorString~'${encodedQuery}~source~(${encodedLogGroups})~lang~'CWLI)`;

  const encodedCloudWatchUrl = encodeURIComponent(cloudWatchUrl);

  return `https://iad.merlon.amazon.dev/console?awsAccountId=${accountId}&awsPartition=aws&accountName=${accountName}&sessionDuration=3600&iamRole=${opsRoleArn}&destination=${encodedCloudWatchUrl}`;
};

export const getEventDateRange = (
  event:
    | DigitalBusinessDocument
    | AccountingEventsGroup
    | CanonicalBusinessEvent
    | undefined,
  isCbe: boolean
): [Date, Date] => {
  let eventDate: Date;

  if (!event) {
    // If event is undefined, use current date
    eventDate = new Date();
  } else if ("creationDate" in event && event.creationDate) {
    eventDate = new Date(event.creationDate);
  } else if (
    "body" in event &&
    event.body &&
    "header" in event.body &&
    event.body.header.transactionDate
  ) {
    eventDate = new Date(event.body.header.transactionDate);
  } else if ("accountingEvents" in event) {
    const dates = Object.values(event.accountingEvents).map(
      (e) => new Date(e.creationDate)
    );
    eventDate = new Date(Math.max(...dates.map((d) => d.getTime())));
  } else {
    eventDate = new Date(); // fallback to current date if no valid date found
  }

  const timeRange = isCbe ? TIME_RANGE.CBE : TIME_RANGE.DEFAULT;
  const startTime = new Date(eventDate.getTime() - minutesToMs(timeRange));
  const endTime = new Date(eventDate.getTime() + minutesToMs(timeRange));
  return [startTime, endTime];
};

export const getDlqDateRange = (timestamp: string): [Date, Date] => {
  const eventDate = new Date(`${timestamp.replace(" ", "T")}Z`);
  const startTime = new Date(
    eventDate.getTime() - minutesToMs(TIME_RANGE.DEFAULT)
  );
  const endTime = new Date(
    eventDate.getTime() + minutesToMs(TIME_RANGE.DEFAULT)
  );
  return [startTime, endTime];
};

export const getEventLogGroups = (
  stage: string,
  eventData: (AccountingEventsGroup | DigitalBusinessDocument)[]
) => {
  const stagePrefix = stage.toLowerCase();

  if (eventData.length > 0 && "accountingEvents" in eventData[0]) {
    // This is an AccountingEventsGroup
    return [
      `/aws/lambda/${stagePrefix}-payment-event-translator`,
      `/aws/lambda/${stagePrefix}-tvod-royalty-event-translator`,
      `/aws/lambda/${stagePrefix}-tvod-revenue-event-translator`,
      `/aws/lambda/${stagePrefix}-revenue-event-translator`,
    ];
  }
  // This is a DigitalBusinessDocument
  return [
    `/aws/lambda/${stagePrefix}-document-builder`,
    `/aws/lambda/${stagePrefix}-document-publisher`,
  ];
};

export const getUslPublisherLogGroups = (stage: string): string[] => {
  const stagePrefix = stage.toLowerCase();
  return [
    `/aws/lambda/${stagePrefix}-usl-journal-entry-publisher-USAmazon`,
    `/aws/lambda/${stagePrefix}-usl-journal-entry-publisher-EUAmazon`,
    `/aws/lambda/${stagePrefix}-usl-journal-entry-publisher-JPAmazon`,
  ];
};

export const getCbeLogGroups = (marketplaceId: string, stage: string) => {
  const realm = MARKETPLACE_TO_REALM[marketplaceId] || "na";
  const stagePrefix = stage.toLowerCase();
  const baseGroups = [`/aws/lambda/${stagePrefix}-accounting-lambda`];

  switch (realm) {
    case "na":
      return [
        ...baseGroups,
        `/aws/lambda/${stagePrefix}-usl-journal-entry-publisher-USAmazon`,
        `/aws/lambda/${stagePrefix}-race-publisher-usamazon`,
      ];
    case "eu":
      return [
        ...baseGroups,
        `/aws/lambda/${stagePrefix}-usl-journal-entry-publisher-EUAmazon`,
        `/aws/lambda/${stagePrefix}-race-publisher-euamazon`,
      ];
    case "fe":
      return [
        ...baseGroups,
        `/aws/lambda/${stagePrefix}-usl-journal-entry-publisher-JPAmazon`,
        `/aws/lambda/${stagePrefix}-race-publisher-jpamazon`,
      ];
    default:
      return baseGroups;
  }
};

export const getQuery = (id: string, isDlq: boolean) => {
  if (isDlq) {
    return `fields @timestamp, contextMap.AWSRequestId, exception.class, message, exception.message, exception.stackTrace
| filter @message like /${id}/ or contextMap.SQSMessageId='${id}'
| filter @message not like /CloudWatchMetrics/
| filter class not like /com.amazon.variable.metrics.EmbeddedProjectableMetrics/
| sort @timestamp desc
| limit 10000`;
  }

  const baseQuery = `fields @timestamp, @entity.Attributes.Lambda.Function as LambdaFunction, message
| filter @message not like /CloudWatchMetrics/`;

  const filterQuery = `| filter @message like /${id}/
    or contextMap.OrderId = '${id}'
    or contextMap.AUID = '${id}'`;

  const sortAndLimitQuery = `| sort LambdaFunction, @timestamp desc
| limit 1000`;

  return `${baseQuery}
${filterQuery}
${sortAndLimitQuery}`;
};

const dlqToLogGroupMapping: { [key: string]: string } = {
  "tvod-revenue-event-translator-intake-dlq": "tvod-revenue-event-translator",
  "revenue-event-translator-intake": "revenue-event-translator",
  "tvod-royalty-event-translator-intake-dlq": "tvod-royalty-event-translator",
  "royalty-event-translator-intake": "royalty-event-translator",
  "payment-event-translator-intake-dlq": "payment-event-translator",
  "document-builder-intake-dlq": "document-builder",
  "subs-accounting-service-intake-dlq": "accounting-lambda",
  "tvod-accounting-service-intake-dlq": "accounting-lambda",
  "accounting-service-intake-dlq": "accounting-lambda",
  "payment-accounting-service-intake-dlq": "accounting-lambda",
  "race-publisher-jpamazon-intake-dlq": "race-publisher-jpamazon",
  "race-publisher-euamazon-intake-dlq": "race-publisher-euamazon",
  "race-publisher-usamazon-intake-dlq": "race-publisher-usamazon",
  "usl-journal-entry-publisher-jpamazon-intake-dlq":
    "usl-journal-entry-publisher-JPAmazon",
  "usl-journal-entry-publisher-euamazon-intake-dlq":
    "usl-journal-entry-publisher-EUAmazon",
  "usl-journal-entry-publisher-usamazon-intake-dlq":
    "usl-journal-entry-publisher-USAmazon",
};

export const getDlqLogGroup = (
  eventsourcekey: string,
  stage: string
): string => {
  const keyWithoutStage = eventsourcekey.replace(/^(prod-|gamma-|beta-)/, "");
  const lambdaName = dlqToLogGroupMapping[keyWithoutStage];
  return `/aws/lambda/${stage.toLowerCase()}-${lambdaName}`;
};

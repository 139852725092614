import { CanonicalBusinessEvent } from "../types/cbes";

export function groupCBEByUsecase(
  cbes: CanonicalBusinessEvent[]
): Map<string, CanonicalBusinessEvent[]> {
  const groupedCBEs = new Map<string, CanonicalBusinessEvent[]>();
  cbes.forEach((cbe) => {
    const usecase = cbe.usecaseName;
    const existingCBEs = groupedCBEs.get(usecase) || [];
    groupedCBEs.set(usecase, [...existingCBEs, cbe]);
  });
  return groupedCBEs;
}

import React, { useMemo } from "react";
import {
  Button,
  DatePicker,
  Header,
  Multiselect,
  Pagination,
  Popover,
  Select,
  Table,
} from "@amzn/awsui-components-react";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { useRecentTransactions } from "../hooks/useRecentTransactions";
import {
  COLUMN_DEFINITIONS,
  DEFAULT_OPTION,
  getModifiedOptions,
  GLS,
  GROUPED_MARKETPLACES,
  GROUPED_USECASES,
  PAYMENT_METHOD_OPTIONS,
  RECENT_TRXN_EMPTY_STATE,
  STAGES,
  TABLE_PREFERENCES,
  TIME_RANGES,
} from "../config/recentTransactionTableConfig";
import ViewLogs from "./common/ViewLogs";
import { getUslPublisherLogGroups } from "../helpers/logUtils";
import { EmptyState } from "./common/EmptyState";

export default function RecentTransactions() {
  const {
    items,
    collectionProps,
    filterProps,
    paginationProps,
    handleSearch,
    isLoading,
    selectedStage,
    selectedGl,
    selectedMarketplaces,
    selectedUsecases,
    selectedPaymentMethod,
    setSelectedStage,
    setSelectedGl,
    setSelectedMarketplaces,
    setSelectedUsecases,
    setSelectedPaymentMethod,
    clearFilter,
    selectedTimeRange,
    setSelectedTimeRange,
    selectedDate,
    setSelectedDate,
    executedQuery,
    error,
  } = useRecentTransactions();

  const modifiedMarketplaces = useMemo(
    () => getModifiedOptions(GROUPED_MARKETPLACES, selectedMarketplaces),
    [selectedMarketplaces]
  );

  const modifiedUsecases = useMemo(
    () => getModifiedOptions(GROUPED_USECASES, selectedUsecases),
    [selectedUsecases]
  );

  const handleMultiselectChange =
    (
      setFunction: (options: OptionDefinition[]) => void,
      currentSelection: readonly OptionDefinition[]
    ) =>
    ({
      detail,
    }: {
      detail: { selectedOptions: readonly OptionDefinition[] };
    }) => {
      const newSelection = [...detail.selectedOptions] as OptionDefinition[];
      const hadAll = currentSelection.some(
        (option) => option.value === DEFAULT_OPTION.value
      );
      const hasAll = newSelection.some(
        (option) => option.value === DEFAULT_OPTION.value
      );

      if (hasAll && !hadAll) {
        setFunction([DEFAULT_OPTION]);
      } else if (!hasAll && hadAll) {
        setFunction([]);
      } else if (hasAll && hadAll) {
        setFunction([]);
      } else {
        setFunction(newSelection);
      }
    };

  return (
    <Table
      {...collectionProps}
      {...TABLE_PREFERENCES}
      resizableColumns
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loading={isLoading}
      loadingText="Loading transactions"
      header={
        <Header variant="awsui-h1-sticky">
          Find Transactions{" "}
          <Popover
            dismissButton={false}
            fixedWidth={false}
            position="bottom"
            triggerType="custom"
            content="Searches are limited to 5 minute intervals from the time range specified. To choose a specific time range, or add custom filters, open in CloudWatch"
          >
            <Button iconName="status-info" variant="inline-icon" />
          </Popover>
        </Header>
      }
      filter={
        <div className="input-container">
          <div className="select-filter" style={{ maxInlineSize: "110px" }}>
            <Select
              data-testid="stage-filter"
              inlineLabelText="Stage"
              options={STAGES}
              selectedAriaLabel="Selected"
              selectedOption={selectedStage}
              onChange={({ detail }) => setSelectedStage(detail.selectedOption)}
              expandToViewport
            />
          </div>
          <div className="select-filter" style={{ maxInlineSize: "150px" }}>
            <Select
              inlineLabelText="GL"
              data-testid="gl-filter"
              options={GLS}
              selectedAriaLabel="Selected"
              selectedOption={selectedGl}
              onChange={({ detail }) => setSelectedGl(detail.selectedOption)}
              expandToViewport
              filteringType="auto"
              filteringPlaceholder="Find GL"
              empty="No matching GLs"
            />
          </div>
          <div className="select-filter" style={{ maxInlineSize: "150px" }}>
            <Select
              inlineLabelText="Payment Type"
              data-testid="payment-method-filter"
              options={PAYMENT_METHOD_OPTIONS}
              selectedAriaLabel="Selected"
              selectedOption={selectedPaymentMethod}
              onChange={({ detail }) =>
                setSelectedPaymentMethod(detail.selectedOption)
              }
              expandToViewport
            />
          </div>
          <div className="select-filter">
            <Multiselect
              placeholder="Marketplaces"
              inlineTokens
              data-testid="marketplace-filter"
              options={modifiedMarketplaces}
              selectedOptions={selectedMarketplaces}
              onChange={handleMultiselectChange(
                setSelectedMarketplaces,
                selectedMarketplaces
              )}
              expandToViewport
              filteringType="auto"
              filteringPlaceholder="Find marketplaces"
              empty="No matching marketplaces"
            />
          </div>
          <div className="select-filter">
            <Multiselect
              placeholder="Usecases"
              data-testid="usecase-filter"
              inlineTokens
              options={modifiedUsecases}
              selectedOptions={selectedUsecases}
              onChange={handleMultiselectChange(
                setSelectedUsecases,
                selectedUsecases
              )}
              filteringType="auto"
              expandToViewport
              filteringPlaceholder="Find usecases"
              empty="No matching usecases"
            />
          </div>
          <div className="select-filter" style={{ maxInlineSize: "150px" }}>
            <Select
              inlineLabelText="Time Range"
              data-testid="time-range-filter"
              options={TIME_RANGES}
              selectedAriaLabel="Selected"
              selectedOption={selectedTimeRange}
              onChange={({ detail }) => {
                setSelectedTimeRange(detail.selectedOption);
                if (detail.selectedOption.value !== "custom") {
                  setSelectedDate("");
                }
              }}
              expandToViewport
            />
          </div>
          {selectedTimeRange.value === "custom" && (
            <div className="select-filter" style={{ maxInlineSize: "200px" }}>
              <DatePicker
                onChange={({ detail }) => setSelectedDate(detail.value)}
                value={selectedDate}
                placeholder="Select a date"
                previousMonthAriaLabel="Previous month"
                nextMonthAriaLabel="Next month"
                todayAriaLabel="Today"
                expandToViewport
                isDateEnabled={(date) => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  return new Date(date) <= today;
                }}
              />
            </div>
          )}

          <Button variant="primary" onClick={handleSearch}>
            Search
          </Button>
          <Button onClick={clearFilter}>Clear filters</Button>
          <ViewLogs
            stage={selectedStage.value!}
            logGroups={getUslPublisherLogGroups(selectedStage.value!)}
            logQuery={executedQuery!}
            minimal
          />
        </div>
      }
      pagination={<Pagination {...paginationProps} />}
      empty={
        <EmptyState
          error={error}
          filteringText={filterProps.filteringText}
          config={RECENT_TRXN_EMPTY_STATE}
        />
      }
    />
  );
}

import { Box, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { useContext } from "react";
import LandingPage from "./LandingPage";
import { OrderIdContext, StageContext } from "../contexes/OrderIdContext";
import useFetchDetails from "../hooks/useFetchDetails";
import useFetchDlqInfo from "../hooks/useFetchDlqInfo";
import Results from "./events/Results";
import { AccountingEventsGroup } from "../types/events";
import Summary from "./summary/Summary";
import ErrorAlert from "./common/ErrorAlert";
import DlqStatusIndicator from "./dlq/DlqStatusIndicator";
import { PERMISSION_LINK } from "../constants";

export default function MainDataPage() {
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);

  const {
    data: detailsData,
    error: detailsError,
    isFetching: detailsFetching,
    isLoading: detailsLoading,
  } = useFetchDetails(stage, orderId);

  const dlqInfo = useFetchDlqInfo(stage, orderId);

  const isEventAndCbeEmpty =
    detailsData?.events.length === 0 && detailsData?.cbes.length === 0;

  const isLoadingData = detailsLoading;
  const isFetchingData = detailsFetching;
  const isError = detailsError !== null;

  return (
    <div>
      {isLoadingData && !isFetchingData ? (
        <LandingPage />
      ) : isFetchingData ? (
        <Box textAlign="center">
          <Spinner size="big" />
        </Box>
      ) : isError ? (
        <ErrorAlert
          header="An error occurred while fetching the data"
          content={
            <>
              Could not fetch the data from server. You might not have{" "}
              <a href={PERMISSION_LINK}>permission</a> to use this tool. (
              {detailsError.message})
            </>
          }
        />
      ) : isEventAndCbeEmpty ? (
        <ErrorAlert
          header="Could not find any events or CBEs with given id"
          content={
            <SpaceBetween size="s">
              <div>
                Transaction with the given order id might not exist, or may have
                DLQ items.
              </div>
              <div>
                DLQ Status:{" "}
                <DlqStatusIndicator
                  status={dlqInfo.status}
                  dlqItems={dlqInfo.dlqItems}
                />
              </div>
            </SpaceBetween>
          }
        />
      ) : (
        <SpaceBetween size="xl">
          <Summary
            eventData={detailsData?.events as AccountingEventsGroup[]}
            dlqInfo={dlqInfo}
          />
          <Results />
        </SpaceBetween>
      )}
    </div>
  );
}

import { Header, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import { useDlqTable } from "../../hooks/useDlqTable";
import { DlqTable } from "./DlqTable";
import { QueueSummary } from "./QueueSummary";
import { JsonModal } from "../common/JsonModal";
import { DLQItem } from "../../lusca-search-api/generated-src";

interface DlqDeepDiveModalProps {
  visible: boolean;
  onDismiss: () => void;
  dlqItems: DLQItem[];
}

interface DlqDeepDiveModalProps {
  visible: boolean;
  onDismiss: () => void;
  dlqItems: DLQItem[];
}

export default function DlqDeepDiveModal({
  visible,
  onDismiss,
  dlqItems,
}: DlqDeepDiveModalProps) {
  const {
    selectedItem,
    setSelectedItem,
    sortingColumn,
    sortingDescending,
    filteringText,
    setFilteringText,
    currentPageIndex,
    setCurrentPageIndex,
    pageSize,
    queueSummary,
    filteredItems,
    paginatedItems,
    handleSortingChange,
  } = useDlqTable(dlqItems);

  return (
    <>
      <Modal
        visible={visible}
        onDismiss={onDismiss}
        size="max"
        header={<Header variant="h2">DLQ Deep Dive</Header>}
      >
        <SpaceBetween direction="vertical" size="l">
          <QueueSummary summary={queueSummary} />
          <DlqTable
            items={paginatedItems}
            sortingColumn={sortingColumn}
            sortingDescending={sortingDescending}
            onSortingChange={handleSortingChange}
            filteringText={filteringText}
            onFilteringChange={setFilteringText}
            currentPageIndex={currentPageIndex}
            onPageChange={setCurrentPageIndex}
            pageSize={pageSize}
            onItemSelect={setSelectedItem}
            filteredItemsCount={filteredItems.length}
          />
        </SpaceBetween>
      </Modal>

      {selectedItem && (
        <JsonModal
          title={selectedItem.is_malformed_json ? "Raw Message" : "Raw JSON"}
          content={selectedItem.processed_body}
          visible
          onDismiss={() => setSelectedItem(null)}
          isMalformedJson={selectedItem.is_malformed_json}
        />
      )}
    </>
  );
}

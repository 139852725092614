import { useContext, useMemo } from "react";
import { OrderIdContext, StageContext } from "../contexes/OrderIdContext";
import { AccountingEventsGroup } from "../types/events";
import {
  DigitalBusinessDocument,
  DigitalCostDocument,
} from "../types/documents";
import { useMarketplaceId } from "../contexes/MarketplaceIdContext";
import { getCountryCodeFromMarketplace } from "../helpers/marketplaceUtils";
import { useHealthStatus } from "./useHealthStatus";
import {
  isAccountingEventsGroup,
  isDigitalBusinessDocument,
  isDigitalCostDocument,
} from "../helpers/typeChecks";
import { DlqInfo } from "./useFetchDlqInfo";
import { useGlProduct } from "../contexes/GlProductContext";

export const GL_MAPPING: Record<string, string> = {
  "318": "TVOD",
  "613": "Channels",
  "628": "SVOD",
  "796": "SWM",
};

export function useSummaryData(
  eventData: (
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | DigitalCostDocument
  )[],
  dlqInfo: DlqInfo
) {
  const stage = useContext(StageContext);
  const orderId = useContext(OrderIdContext);
  const { marketplaceId } = useMarketplaceId();
  const { setGlProduct } = useGlProduct();
  const countryCode = getCountryCodeFromMarketplace(marketplaceId);
  const {
    healthStatus,
    steps,
    state: healthState,
  } = useHealthStatus(dlqInfo.status);

  const {
    orderDate,
    glProductGroup,
    consumptionType,
    fulfillmentType,
    lastItem,
  } = useMemo(() => {
    if (!eventData || eventData.length === 0) {
      return {
        orderDate: undefined,
        glProductGroup: undefined,
        consumptionType: undefined,
        fulfillmentType: undefined,
        lastItem: undefined,
      };
    }

    const lastEvent = eventData[eventData.length - 1];

    if (isAccountingEventsGroup(lastEvent)) {
      const revenueEvent = Object.values(lastEvent.accountingEvents).find(
        (event) => event.type === "REVENUE"
      );
      const fallbackEvent = Object.values(lastEvent.accountingEvents).find(
        (event) => event.type === "ROYALTY"
      );
      const selectedEvent = revenueEvent || fallbackEvent;

      return {
        orderDate: selectedEvent?.orderDate,
        glProductGroup: selectedEvent?.glProductGroup,
        consumptionType: revenueEvent?.consumptionType,
        lastItem: { accountingEventsGroup: lastEvent },
      };
    }

    if (isDigitalCostDocument(lastEvent)) {
      return {
        orderDate: lastEvent.body.header.transactionDate,
        glProductGroup: lastEvent.body.body.glProductLineId,
        consumptionType: lastEvent.body.body.cost.transactionType,
        lastItem: { digitalCostDocument: lastEvent },
      };
    }

    if (isDigitalBusinessDocument(lastEvent)) {
      const firstProduct = Object.values(lastEvent.body.body.products)[0];
      return {
        orderDate: lastEvent.body.header.transactionDate,
        glProductGroup: firstProduct?.details.glProductLineId,
        consumptionType: firstProduct?.details.activity,
        fulfillmentType:
          lastEvent.body.metadata.internalAttributes?.FULFILLMENT_TYPE,
        lastItem: { digitalBusinessDocument: lastEvent },
      };
    }

    return {
      orderDate: undefined,
      glProductGroup: undefined,
      consumptionType: undefined,
      lastItem: undefined,
    };
  }, [eventData]);

  const getGlMapping = (gl: number | string) => GL_MAPPING[gl.toString()] ?? gl;
  setGlProduct(
    glProductGroup ? getGlMapping(glProductGroup.toString()) : "unknown"
  );
  // Health should continue loading until it is not unknown
  // In some cases health will never need to be loading (i.e. if we immediately know it is unhealthy)
  // But in some cases health needs to wait until the DLQ info has fetched
  const isHealthLoading = healthState.overallHealth === "unknown";

  return {
    stage,
    orderId,
    countryCode,
    healthStatus,
    steps,
    orderDate,
    glProductGroup,
    consumptionType,
    fulfillmentType,
    getGlMapping,
    lastItem,
    isHealthLoading,
  };
}

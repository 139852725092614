import { Box, Button } from "@amzn/awsui-components-react";
import { EmptyStateDefinition } from "../../config/recentTransactionTableConfig";

interface EmptyStateProps {
  error: Error | null;
  filteringText: string;
  config: EmptyStateDefinition;
}

export function EmptyState({ error, filteringText, config }: EmptyStateProps) {
  const state = error
    ? config.permission
    : filteringText
    ? config.filtered
    : config.default;

  return (
    <Box textAlign="center" color="inherit">
      <b>{state.heading}</b>
      <Box padding={{ bottom: "s" }} variant="p" color="inherit">
        {state.message}
      </Box>
      {state.link && (
        <Button variant="primary" href={state.link}>
          Request Access
        </Button>
      )}
    </Box>
  );
}

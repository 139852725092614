import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  DEFAULT_OPTION,
  PAYMENT_METHOD_OPTIONS,
  STAGES,
  TIME_RANGES,
} from "../config/recentTransactionTableConfig";
import { RecentTransactionsSearchParams } from "../types/common";
import { Transaction } from "../lusca-search-api/generated-src";
import useFetchRecentTransactions from "./useFetchRecentTransactions";
import { GL_MAPPING } from "./useSummaryData";

export function useRecentTransactions() {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const [currentServerPage, setCurrentServerPage] = useState(1);
  const [selectedStage, setSelectedStage] = useState<OptionDefinition>(
    STAGES[0]
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    PAYMENT_METHOD_OPTIONS[0]
  );
  const [selectedGl, setSelectedGl] =
    useState<OptionDefinition>(DEFAULT_OPTION);
  const [selectedMarketplaces, setSelectedMarketplaces] = useState<
    OptionDefinition[]
  >([]);
  const [selectedUsecases, setSelectedUsecases] = useState<OptionDefinition[]>(
    []
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState<OptionDefinition>(
    TIME_RANGES[1]
  );
  const [selectedDate, setSelectedDate] = useState<string>("");

  const [apiSearchParams, setApiSearchParams] =
    useState<RecentTransactionsSearchParams>({
      stage: STAGES[0].value,
    });

  const getEndTime = (
    range: OptionDefinition,
    date: string
  ): number | undefined => {
    if (range.value === "custom" && date) {
      const dateAtNoon = new Date(date);
      dateAtNoon.setUTCHours(12, 0, 0, 0);
      return Math.floor(dateAtNoon.getTime() / 1000);
    }
    const now = Math.floor(Date.now() / 1000);
    switch (range.value) {
      case "5min":
        return now - 5 * 60;
      case "15min":
        return now - 15 * 60;
      case "1hour":
        return now - 60 * 60;
      case "24hours":
        return now - 24 * 60 * 60;
      case "1week":
        return now - 7 * 24 * 60 * 60;
      case "1month":
        return now - 30 * 24 * 60 * 60;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const stageParam = urlSearchParams.get("stage");
    const glParam = urlSearchParams.get("gl");
    const countriesParam = urlSearchParams.get("countries");
    const usecasesParam = urlSearchParams.get("usecases");
    const paymentMethodParam = urlSearchParams.get("paymentMethod");
    const timeRangeParam = urlSearchParams.get("timeRange");
    const dateParam = urlSearchParams.get("date");

    const stageOption =
      STAGES.find((opt) => opt.value === stageParam) || STAGES[0];
    const glOption = glParam
      ? { value: glParam, label: GL_MAPPING[glParam] || glParam }
      : DEFAULT_OPTION;
    const paymentMethodOption =
      PAYMENT_METHOD_OPTIONS.find((pm) => pm.value === paymentMethodParam) ||
      PAYMENT_METHOD_OPTIONS[0];
    const timeRangeOption =
      TIME_RANGES.find((tr) => tr.value === timeRangeParam) || TIME_RANGES[1];
    const marketplaces = countriesParam
      ? countriesParam.split(",").map((c) => ({ value: c, label: c }))
      : [];
    const usecases = usecasesParam
      ? usecasesParam.split(",").map((u) => ({ value: u, label: u }))
      : [];

    setSelectedStage(stageOption);
    setSelectedGl(glOption);
    setSelectedMarketplaces(marketplaces);
    setSelectedUsecases(usecases);
    setSelectedPaymentMethod(paymentMethodOption);
    setSelectedTimeRange(timeRangeOption);
    setSelectedDate(dateParam || "");

    const newParams: RecentTransactionsSearchParams = {
      stage: stageOption.value,
      ...(glOption.value &&
        glOption.value !== DEFAULT_OPTION.value && { gl: glOption.value }),
      ...(marketplaces.length > 0 &&
        !marketplaces.some((m) => m.value === DEFAULT_OPTION.value) && {
          countries: marketplaces.map((m) => m.value),
        }),
      ...(usecases.length > 0 &&
        !usecases.some((u) => u.value === DEFAULT_OPTION.value) && {
          usecases: usecases.map((u) => u.value),
        }),
      ...(paymentMethodOption.value !== "all" && {
        paymentMethod: paymentMethodOption.value!,
      }),
      endTime: getEndTime(timeRangeOption, dateParam || ""),
    };

    setApiSearchParams(newParams);
    setCurrentServerPage(1);
    setAllTransactions([]);
    actions.setCurrentPage(1);
  }, [urlSearchParams]);

  const [allTransactions, setAllTransactions] = useState<Transaction[]>([]);
  const { transactions, nextPageTimestamp, executedQuery, isLoading, error } =
    useFetchRecentTransactions(apiSearchParams);

  useEffect(() => {
    if (!isLoading && transactions.length > 0) {
      setAllTransactions((prev) => {
        const newTransactions = transactions.filter(
          (newTx) => !prev.some((prevTx) => prevTx.id === newTx.id)
        );
        return [...prev, ...newTransactions];
      });
    }
  }, [transactions, isLoading]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(allTransactions, {
    filtering: {
      filteringFunction: (item: Transaction, filteringText: string) => {
        if (!filteringText) return true;
        const filterText = filteringText.toLowerCase();
        const searchableValues = [
          item.id,
          item.stage,
          item.country,
          item.gl,
          GL_MAPPING[item.gl],
          item.usecase,
          item.paymentMethod,
          new Date(item.processedTime).toLocaleString(),
        ];
        return searchableValues.some((value) =>
          String(value).toLowerCase().includes(filterText)
        );
      },
    },
    pagination: { pageSize: 15 },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: "processedTime" },
        isDescending: true,
      },
    },
  });

  const isFiltering = !!filterProps.filteringText;
  const handleSearch = () => {
    const newParams: RecentTransactionsSearchParams = {
      stage: selectedStage.value!,
      ...(selectedGl?.value &&
        selectedGl.value !== DEFAULT_OPTION.value && { gl: selectedGl.value }),
      ...(selectedMarketplaces.length > 0 &&
        !selectedMarketplaces.some((m) => m.value === DEFAULT_OPTION.value) && {
          countries: selectedMarketplaces
            .map((m) => m.value!)
            .filter((v): v is string =>
              Boolean(v && v !== DEFAULT_OPTION.value)
            ),
        }),
      ...(selectedUsecases.length > 0 &&
        !selectedUsecases.some((u) => u.value === DEFAULT_OPTION.value) && {
          usecases: selectedUsecases
            .map((u) => u.value!)
            .filter((v): v is string =>
              Boolean(v && v !== DEFAULT_OPTION.value)
            ),
        }),
      ...(selectedPaymentMethod.value !== "all" && {
        paymentMethod: selectedPaymentMethod.value!,
      }),
      endTime: getEndTime(selectedTimeRange, selectedDate),
    };

    const params: Record<string, string> = {};
    params.stage = selectedStage.value!;
    if (selectedGl.value != null && selectedGl.value !== DEFAULT_OPTION.value) {
      params.gl = selectedGl.value;
    }
    if (
      selectedMarketplaces.length > 0 &&
      !selectedMarketplaces.some((m) => m.value === DEFAULT_OPTION.value)
    ) {
      params.countries = selectedMarketplaces.map((m) => m.value!).join(",");
    }
    if (
      selectedUsecases.length > 0 &&
      !selectedUsecases.some((u) => u.value === DEFAULT_OPTION.value)
    ) {
      params.usecases = selectedUsecases.map((u) => u.value!).join(",");
    }
    if (selectedPaymentMethod.value !== "all") {
      params.paymentMethod = selectedPaymentMethod.value!;
    }
    params.timeRange = selectedTimeRange.value!;
    if (selectedDate) {
      params.date = selectedDate;
    }
    setUrlSearchParams(params);

    setApiSearchParams(newParams);
    setCurrentServerPage(1);
    setAllTransactions([]);
    actions.setCurrentPage(1);
  };

  const clearFilter = () => {
    actions.setFiltering("");
    setSelectedGl(DEFAULT_OPTION);
    setSelectedMarketplaces([]);
    setSelectedUsecases([]);
    setSelectedPaymentMethod(PAYMENT_METHOD_OPTIONS[0]);
    setSelectedStage(STAGES[0]);
    setSelectedTimeRange(TIME_RANGES[1]);
    setSelectedDate("");
  };

  const handlePageChange = ({
    detail,
  }: {
    detail: { currentPageIndex: number };
  }) => {
    const newPage = detail.currentPageIndex;
    actions.setCurrentPage(newPage);
    if (newPage > currentServerPage && nextPageTimestamp && !isFiltering) {
      setCurrentServerPage(newPage);
      setApiSearchParams((prev) => ({
        ...prev,
        endTime: nextPageTimestamp,
      }));
    }
  };

  const pagesCount = (() => {
    if (isFiltering) return Math.ceil((filteredItemsCount || 0) / 15) || 1;
    if (isLoading && currentServerPage === 1) return 2;
    return nextPageTimestamp ? currentServerPage + 1 : currentServerPage;
  })();

  const customPaginationProps = {
    currentPageIndex: paginationProps.currentPageIndex,
    pagesCount,
    openEnd: !isFiltering,
    disabled: isLoading,
    onChange: handlePageChange,
  };

  return {
    items,
    actions,
    collectionProps,
    filterProps,
    paginationProps: customPaginationProps,
    handleSearch,
    isLoading,
    searchParams: apiSearchParams,
    selectedStage,
    selectedGl,
    selectedMarketplaces,
    selectedUsecases,
    setSelectedStage,
    setSelectedGl,
    setSelectedMarketplaces,
    setSelectedUsecases,
    clearFilter,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    selectedTimeRange,
    setSelectedTimeRange,
    selectedDate,
    setSelectedDate,
    executedQuery,
    error,
  };
}

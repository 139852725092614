/* eslint-disable react/jsx-no-constructed-context-values */
import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Homepage from "./home";
import Dashboard from "./dashboard/Dashboard";

export default function App() {
  const queryClient = new QueryClient();

  document.body.classList.add("awsui-visual-refresh");

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/find" element={<Homepage />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </QueryClientProvider>
  );
}

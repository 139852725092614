/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Lusca Search
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Cbe
 */
export interface Cbe {
    /**
     * 
     * @type {string}
     * @memberof Cbe
     */
    'orderId'?: string;
}
/**
 * 
 * @export
 * @interface DLQInfoResult
 */
export interface DLQInfoResult {
    /**
     * The status of the DLQ query (e.g., \"pending\", \"completed\", \"failed\")
     * @type {string}
     * @memberof DLQInfoResult
     */
    'status': string;
    /**
     * The ID of the query execution (present when status is \"pending\")
     * @type {string}
     * @memberof DLQInfoResult
     */
    'queryExecutionId'?: string;
    /**
     * Whether the order is in DLQ (present when status is \"completed\")
     * @type {boolean}
     * @memberof DLQInfoResult
     */
    'inDLQ'?: boolean;
    /**
     * The DLQ items (present when status is \"completed\" and inDLQ is true)
     * @type {Array<DLQItem>}
     * @memberof DLQInfoResult
     */
    'dlqItems'?: Array<DLQItem>;
    /**
     * 
     * @type {string}
     * @memberof DLQInfoResult
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface DLQItem
 */
export interface DLQItem {
    /**
     * The primary key of the DLQ item
     * @type {string}
     * @memberof DLQItem
     */
    'pk': string;
    /**
     * The name of the queue where the message failed
     * @type {string}
     * @memberof DLQItem
     */
    'eventsourcekey': string;
    /**
     * The timestamp when the message failed processing
     * @type {string}
     * @memberof DLQItem
     */
    'timestamp': string;
    /**
     * 
     * @type {DLQItemProcessedBody}
     * @memberof DLQItem
     */
    'processed_body': DLQItemProcessedBody;
    /**
     * Indicates whether the message is a raw string (true) or valid JSON object (false)
     * @type {boolean}
     * @memberof DLQItem
     */
    'is_malformed_json': boolean;
}
/**
 * @type DLQItemProcessedBody
 * The message body, either as a raw string (if malformed) or parsed JSON object
 * @export
 */
export type DLQItemProcessedBody = string | { [key: string]: any; };

/**
 * 
 * @export
 * @interface DashboardEmbedResponse
 */
export interface DashboardEmbedResponse {
    /**
     * The URL for embedding the dashboard
     * @type {string}
     * @memberof DashboardEmbedResponse
     */
    'embedUrl': string;
    /**
     * The request ID for this embed URL generation
     * @type {string}
     * @memberof DashboardEmbedResponse
     */
    'requestId': string;
}
/**
 * 
 * @export
 * @interface DetailsResponse
 */
export interface DetailsResponse {
    /**
     * 
     * @type {Array<{ [key: string]: any; }>}
     * @memberof DetailsResponse
     */
    'events'?: Array<{ [key: string]: any; }>;
    /**
     * 
     * @type {Array<{ [key: string]: any; }>}
     * @memberof DetailsResponse
     */
    'cbes'?: Array<{ [key: string]: any; }>;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'orderId'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface RecentTransactionsResponse
 */
export interface RecentTransactionsResponse {
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof RecentTransactionsResponse
     */
    'transactions': Array<Transaction>;
    /**
     * Timestamp to use for fetching the next page of results
     * @type {number}
     * @memberof RecentTransactionsResponse
     */
    'nextPageTimestamp'?: number;
    /**
     * The CloudWatch Logs Insights query that was executed
     * @type {string}
     * @memberof RecentTransactionsResponse
     */
    'executedQuery'?: string;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * Either AUID or OrderId depending on GL code
     * @type {string}
     * @memberof Transaction
     */
    'id': string;
    /**
     * Environment (Prod, Gamma, Beta)
     * @type {string}
     * @memberof Transaction
     */
    'stage': TransactionStageEnum;
    /**
     * Country code (e.g., US, GB, DE)
     * @type {string}
     * @memberof Transaction
     */
    'country': string;
    /**
     * GL code (e.g., 318, 613)
     * @type {string}
     * @memberof Transaction
     */
    'gl': string;
    /**
     * Time when the transaction was processed
     * @type {string}
     * @memberof Transaction
     */
    'processedTime': string;
    /**
     * Simplified transaction type
     * @type {string}
     * @memberof Transaction
     */
    'usecase': string;
    /**
     * Payment method used for the transaction
     * @type {string}
     * @memberof Transaction
     */
    'paymentMethod': string;
}

export const TransactionStageEnum = {
    Prod: 'Prod',
    Gamma: 'Gamma',
    Beta: 'Beta'
} as const;

export type TransactionStageEnum = typeof TransactionStageEnum[keyof typeof TransactionStageEnum];


/**
 * CbesApi - axios parameter creator
 * @export
 */
export const CbesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of cbe\'s
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCbes: async (stage: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getCbes', 'stage', stage)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getCbes', 'orderId', orderId)
            const localVarPath = `/cbes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of CBEs and events
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetails: async (stage: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getDetails', 'stage', stage)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getDetails', 'orderId', orderId)
            const localVarPath = `/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CbesApi - functional programming interface
 * @export
 */
export const CbesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CbesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of cbe\'s
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCbes(stage: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Cbe>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCbes(stage, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of CBEs and events
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetails(stage: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetails(stage, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CbesApi - factory interface
 * @export
 */
export const CbesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CbesApiFp(configuration)
    return {
        /**
         * 
         * @summary List of cbe\'s
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCbes(stage: string, orderId: string, options?: any): AxiosPromise<Array<Cbe>> {
            return localVarFp.getCbes(stage, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of CBEs and events
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetails(stage: string, orderId: string, options?: any): AxiosPromise<DetailsResponse> {
            return localVarFp.getDetails(stage, orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CbesApi - object-oriented interface
 * @export
 * @class CbesApi
 * @extends {BaseAPI}
 */
export class CbesApi extends BaseAPI {
    /**
     * 
     * @summary List of cbe\'s
     * @param {string} stage environment name
     * @param {string} orderId ID of the order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CbesApi
     */
    public getCbes(stage: string, orderId: string, options?: AxiosRequestConfig) {
        return CbesApiFp(this.configuration).getCbes(stage, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of CBEs and events
     * @param {string} stage environment name
     * @param {string} orderId ID of the order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CbesApi
     */
    public getDetails(stage: string, orderId: string, options?: AxiosRequestConfig) {
        return CbesApiFp(this.configuration).getDetails(stage, orderId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get QuickSight dashboard embed URL
         * @param {string} stage environment name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardEmbedUrl: async (stage: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getDashboardEmbedUrl', 'stage', stage)
            const localVarPath = `/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get QuickSight dashboard embed URL
         * @param {string} stage environment name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardEmbedUrl(stage: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardEmbedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardEmbedUrl(stage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Get QuickSight dashboard embed URL
         * @param {string} stage environment name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardEmbedUrl(stage: string, options?: any): AxiosPromise<DashboardEmbedResponse> {
            return localVarFp.getDashboardEmbedUrl(stage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Get QuickSight dashboard embed URL
     * @param {string} stage environment name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getDashboardEmbedUrl(stage: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getDashboardEmbedUrl(stage, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * DlqApi - axios parameter creator
 * @export
 */
export const DlqApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check DLQ status and get details for a transaction
         * @param {string} stage environment name
         * @param {string} [orderId] ID of the transaction to check
         * @param {string} [queryExecutionId] ID of the query execution
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDLQInfo: async (stage: string, orderId?: string, queryExecutionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getDLQInfo', 'stage', stage)
            const localVarPath = `/dlq-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (queryExecutionId !== undefined) {
                localVarQueryParameter['queryExecutionId'] = queryExecutionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DlqApi - functional programming interface
 * @export
 */
export const DlqApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DlqApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check DLQ status and get details for a transaction
         * @param {string} stage environment name
         * @param {string} [orderId] ID of the transaction to check
         * @param {string} [queryExecutionId] ID of the query execution
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDLQInfo(stage: string, orderId?: string, queryExecutionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DLQInfoResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDLQInfo(stage, orderId, queryExecutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DlqApi - factory interface
 * @export
 */
export const DlqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DlqApiFp(configuration)
    return {
        /**
         * 
         * @summary Check DLQ status and get details for a transaction
         * @param {string} stage environment name
         * @param {string} [orderId] ID of the transaction to check
         * @param {string} [queryExecutionId] ID of the query execution
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDLQInfo(stage: string, orderId?: string, queryExecutionId?: string, options?: any): AxiosPromise<DLQInfoResult> {
            return localVarFp.getDLQInfo(stage, orderId, queryExecutionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DlqApi - object-oriented interface
 * @export
 * @class DlqApi
 * @extends {BaseAPI}
 */
export class DlqApi extends BaseAPI {
    /**
     * 
     * @summary Check DLQ status and get details for a transaction
     * @param {string} stage environment name
     * @param {string} [orderId] ID of the transaction to check
     * @param {string} [queryExecutionId] ID of the query execution
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlqApi
     */
    public getDLQInfo(stage: string, orderId?: string, queryExecutionId?: string, options?: AxiosRequestConfig) {
        return DlqApiFp(this.configuration).getDLQInfo(stage, orderId, queryExecutionId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of CBEs and events
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetails: async (stage: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getDetails', 'stage', stage)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getDetails', 'orderId', orderId)
            const localVarPath = `/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of events with different versions
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: async (stage: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getEvents', 'stage', stage)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getEvents', 'orderId', orderId)
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of CBEs and events
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetails(stage: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetails(stage, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of events with different versions
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvents(stage: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Event>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(stage, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @summary List of CBEs and events
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetails(stage: string, orderId: string, options?: any): AxiosPromise<DetailsResponse> {
            return localVarFp.getDetails(stage, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of events with different versions
         * @param {string} stage environment name
         * @param {string} orderId ID of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(stage: string, orderId: string, options?: any): AxiosPromise<Array<Event>> {
            return localVarFp.getEvents(stage, orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @summary List of CBEs and events
     * @param {string} stage environment name
     * @param {string} orderId ID of the order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getDetails(stage: string, orderId: string, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getDetails(stage, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of events with different versions
     * @param {string} stage environment name
     * @param {string} orderId ID of the order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEvents(stage: string, orderId: string, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEvents(stage, orderId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get recent transactions across regions
         * @param {string} stage environment name
         * @param {Array<string>} [countries] country codes to filter by (e.g., US, GB, DE)
         * @param {string} [gl] GL code to filter by (e.g., 318, 613)
         * @param {Array<string>} [usecases] usecase types to filter by
         * @param {string} [paymentMethod] filter for payment methods
         * @param {number} [endTime] timestamp for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentTransactions: async (stage: string, countries?: Array<string>, gl?: string, usecases?: Array<string>, paymentMethod?: string, endTime?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getRecentTransactions', 'stage', stage)
            const localVarPath = `/recent-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (gl !== undefined) {
                localVarQueryParameter['gl'] = gl;
            }

            if (usecases) {
                localVarQueryParameter['usecases'] = usecases;
            }

            if (paymentMethod !== undefined) {
                localVarQueryParameter['paymentMethod'] = paymentMethod;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get recent transactions across regions
         * @param {string} stage environment name
         * @param {Array<string>} [countries] country codes to filter by (e.g., US, GB, DE)
         * @param {string} [gl] GL code to filter by (e.g., 318, 613)
         * @param {Array<string>} [usecases] usecase types to filter by
         * @param {string} [paymentMethod] filter for payment methods
         * @param {number} [endTime] timestamp for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentTransactions(stage: string, countries?: Array<string>, gl?: string, usecases?: Array<string>, paymentMethod?: string, endTime?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentTransactionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentTransactions(stage, countries, gl, usecases, paymentMethod, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get recent transactions across regions
         * @param {string} stage environment name
         * @param {Array<string>} [countries] country codes to filter by (e.g., US, GB, DE)
         * @param {string} [gl] GL code to filter by (e.g., 318, 613)
         * @param {Array<string>} [usecases] usecase types to filter by
         * @param {string} [paymentMethod] filter for payment methods
         * @param {number} [endTime] timestamp for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentTransactions(stage: string, countries?: Array<string>, gl?: string, usecases?: Array<string>, paymentMethod?: string, endTime?: number, options?: any): AxiosPromise<RecentTransactionsResponse> {
            return localVarFp.getRecentTransactions(stage, countries, gl, usecases, paymentMethod, endTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * 
     * @summary Get recent transactions across regions
     * @param {string} stage environment name
     * @param {Array<string>} [countries] country codes to filter by (e.g., US, GB, DE)
     * @param {string} [gl] GL code to filter by (e.g., 318, 613)
     * @param {Array<string>} [usecases] usecase types to filter by
     * @param {string} [paymentMethod] filter for payment methods
     * @param {number} [endTime] timestamp for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getRecentTransactions(stage: string, countries?: Array<string>, gl?: string, usecases?: Array<string>, paymentMethod?: string, endTime?: number, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getRecentTransactions(stage, countries, gl, usecases, paymentMethod, endTime, options).then((request) => request(this.axios, this.basePath));
    }
}


import { useState } from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import PageHeader from "../PageHeader";
import HomepageContent from "./HomepageContent";
import "./styles.scss";
import DarkModeContext from "../../contexes/DarkModeContext";
import { PROD_STAGE } from "../../constants";
import { StageContext } from "../../contexes/OrderIdContext";
import { MarketplaceIdProvider } from "../../contexes/MarketplaceIdContext";
import { HealthStateProvider } from "../../contexes/HealthStateContext";
import { DeferralIdProvider } from "../../contexes/DeferralIdContext";
import { GlProductProvider } from "../../contexes/GlProductContext";

export default function Homepage() {
  const [darkMode, setDarkMode] = useState(false);
  const [stage, setStage] = useState(PROD_STAGE);

  return (
    <GlProductProvider>
      <HealthStateProvider>
        <MarketplaceIdProvider>
          <DeferralIdProvider>
            <DarkModeContext.Provider value={darkMode}>
              <StageContext.Provider value={stage}>
                <PageHeader setDarkMode={setDarkMode} />
                <AppLayout
                  content={<HomepageContent setStage={setStage} />}
                  contentType="default"
                  headerSelector="#h"
                  maxContentWidth={1700}
                  navigationHide
                  toolsHide
                />
              </StageContext.Provider>
            </DarkModeContext.Provider>
          </DeferralIdProvider>
        </MarketplaceIdProvider>
      </HealthStateProvider>
    </GlProductProvider>
  );
}

import {
  Button,
  CopyToClipboard,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { useState } from "react";

interface ActionButtonsProps {
  modalHandler: () => void;
  toggleAllSections: (expand: boolean) => void;
  copyConfig?: {
    textToCopy: string;
    buttonText: string;
    successText: string;
    errorText: string;
  };
}

export function ActionButtons({
  modalHandler,
  toggleAllSections,
  copyConfig,
}: ActionButtonsProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => {
    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);
    toggleAllSections(newExpandedState);
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button onClick={modalHandler} iconName="command-prompt">
        View JSON
      </Button>
      <Button
        onClick={handleToggle}
        iconName={isExpanded ? "zoom-out" : "zoom-in"}
      >
        {isExpanded ? "Collapse All" : "Expand All"}
      </Button>
      {copyConfig && (
        <CopyToClipboard
          textToCopy={copyConfig.textToCopy}
          copyButtonText={copyConfig.buttonText}
          copySuccessText={copyConfig.successText}
          copyErrorText={copyConfig.errorText}
        />
      )}
    </SpaceBetween>
  );
}
